import React, { Component } from "react"
import { Helmet } from "react-helmet"

class YAMap extends Component {
  render(props) {
    return (
      <React.Fragment>
        <Helmet>
          <script type="text/javascript" charSet="utf-8" async
                  src="https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3A5a92d29f2fb1daa39d2402353088e399f4b3dcd87b4b7a2af121028f2e903a06&amp;id=yandexmap&amp;height=477&amp;lang=ru_RU&amp;scroll=false"/>
        </Helmet>
        <div id='yandexmap'/>
      </React.Fragment>
    )
  }
}

export default YAMap